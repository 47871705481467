import React, { FC, useEffect, useState } from 'react'

import style from '@root-gipro/modules/AdminPanel/styles/adminPanel.module.scss'

import { Button } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Routes } from '@root-gipro/router'
import { useDispatch } from 'react-redux'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import { getDeflators } from '../userProjects/store/actions'
import AdminPanelCompany from './components/AdminCompanies/AdminPanelCompany'
import AdminPanel from './components/AdminPanel/AdminPanel'
import AdminSettings from './components/AdminSettings/AdminSettings'
import { ROLE_DEVELOPER } from './store/helpers'

const drawerWidth = 256

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: '0 auto',
			maxWidth: '1280px',
			padding: 0,
		},
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		appBar: {
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: drawerWidth,
			},
		},
		menuButton: {
			marginRight: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
		},

		content: {
			flexGrow: 1,
			padding: theme.spacing(2),
		},
	})
)

const drawerList = [
	{ title: 'Пользователи', slug: 'users' },
	{ title: 'Компании', slug: 'companies' },
	{ title: 'Настройки', slug: 'settings' },
]

const AdminContainer: FC = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const [mobileOpen, setMobileOpen] = useState(false)
	const roleId = localStorage.getItem('roleId')

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	useEffect(() => {
		dispatch(getDeflators())
	}, [])

	const drawer = (
		<div>
			<List>
				{drawerList.map(item => (
					<ListItem
						button
						key={item.title}
						component={NavLink}
						activeClassName={style.active}
						className={style.navLink}
						to={'/admin/' + item.slug}
					>
						<ListItemText primary={item.title} />
					</ListItem>
				))}
			</List>
		</div>
	)

	return (
		<div className={classes.root}>
			<main className={classes.content}>
				<div className={style.navbar}>
					{drawerList.map(item => {
						if (
							item.slug !== 'settings' ||
							(item.slug === 'settings' && Number(roleId) === ROLE_DEVELOPER)
						) {
							return (
								<Button
									key={item.title}
									component={NavLink}
									activeClassName={style.active}
									className={style.navLink}
									to={'/admin/' + item.slug}
								>
									{item.title}
								</Button>
							)
						}
					})}
				</div>
				<Switch>
					<Route path={Routes.ADMIN_USERS} component={AdminPanel} />
					<Route path={Routes.ADMIN_COMPANIES} component={AdminPanelCompany} />
					<Route path={Routes.ADMIN_SETTINGS} component={AdminSettings} />
					<Redirect from={Routes.ADMIN} to={Routes.ADMIN_USERS} />
				</Switch>
			</main>
		</div>
	)
}

export default AdminContainer
