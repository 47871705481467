import React, { FC, ReactText, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalContext } from '@root-gipro/context/modal-context'
import { Comment, Region, Voltage } from '@root-gipro/modules/userProjects/components/Project/DropDown'
import { cellTags } from '@root-gipro/modules/userProjects/models/consts/unc-cell-tags.const'
import { tbodyArr } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'
import { ITbodyProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'
import {
	changeCfArchitecture,
	changeComment,
	changeRegion,
	changeVoltage,
	setActiveUncs,
} from '@root-gipro/modules/userProjects/store/actions'
import { getTargetVoltageValues } from '@root-gipro/modules/userProjects/utils/helpers'
import { IRegion, IUnc } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import { useRouteMatch } from 'react-router-dom'
import { RowCells } from './RowCells'

const Tbody: FC<ITbodyProps> = ({ uncs, checkedTable, uncsFiltered }) => {
	const [objectName, setObjectName] = useState<any>()
	const [stage, setStage] = useState<any>()

	const [kf, setKf] = useState<any>({ kf: 1, kf1: 1, kf2: 1, kf3: 1 })
	const [description, setDescription] = useState<any>()
	const [comment, setComment] = useState<string | undefined>()
	const [complicatingParams, setComplicatingParams] = useState<any>(null)
	const [complicatingSelects, setComplicatingSelects] = useState<any>(null)
	const [uncSort, setUncSort] = useState<string | null>(null)

	const [checkedRegionId, setCheckedRegion] = useState<ReactText | null>()
	const [checkedZoneId, setCheckedZoneId] = useState<string | null>(null)
	const { activeUncs, originalObj, projectObj } = useSelector((state: IState) => state.userProjects)
	const { regions } = useSelector((state: IState) => state.public)
	const { setAnchorEl, anchorEl, accessoryRef } = useContext(ModalContext)
	const dispatch = useDispatch()
	const voltageRef = useRef<string>()
	const routeMatch = useRouteMatch()
	const typePageUrl: string = routeMatch.path.split('/')[1]
	const [isArchived, setIsArchived] = useState<boolean>(false)

	const current = accessoryRef && Number(accessoryRef?.current)
	const index = current && current < 0 ? 0 : current
	const getId = () => {
		return activeUncs[index as number]?.id
	}
	useEffect(() => {
		const isLocked = typePageUrl.includes('user-projects') && originalObj.locked
		setIsArchived(typePageUrl.includes('user-archive') || typePageUrl.includes('common-projects') || isLocked)
	}, [originalObj])
	const pushCommentPopup = () => {
		dispatch(
			changeComment(
				activeUncs[index as number]?.id,
				objectName,
				stage,
				kf,
				description,
				comment!,
				complicatingParams,
				true
			)
		)
		setAnchorEl!(null!)
		dispatch(setActiveUncs([]))
		setComment('')
	}

	const coefficentUpdate = (complicatingParam: any) => {
		setComplicatingParams(() => complicatingParam)
		dispatch(changeCfArchitecture(getId(), complicatingParam))
	}

	const confirmRegionHandler = useCallback(
		(regionId, zoneId) => {
			setAnchorEl!(null!)
			activeUncs.forEach(unc => dispatch(changeRegion(unc.id, regionId, zoneId)))
		},
		[activeUncs]
	)
	const regionList: IRegion[] = useMemo(() => {
		return regions?.filter((elem: IRegion) => originalObj.regionIds.includes(elem.id))
	}, [originalObj, regions])

	useEffect(() => {
		if (anchorEl && anchorEl!.dataset.popup === 'region') setCheckedRegion(anchorEl!.dataset.region)
		if (anchorEl && anchorEl!.dataset.popup === 'comment') {
			const current = accessoryRef && Number(accessoryRef?.current)
			const index = current && current < 0 ? 0 : current
			if (activeUncs.length !== 0) {
				activeUncs[index as number].objectName === null
					? setObjectName('')
					: setObjectName(activeUncs[index as number].objectName)
				stage == null && setStage(activeUncs[index as number].stage)
				setKf((prev: any) => {
					return {
						...prev,
						kf: activeUncs[index as number].kf,
						kf1: activeUncs[index as number].kf1,
						kf2: activeUncs[index as number].kf2,
						kf3: activeUncs[index as number].kf3,
					}
				})
				description == null && setDescription(activeUncs[index as number].description)
				setComment(activeUncs[index as number].comment)
				setComplicatingParams(activeUncs[index as number]?.complicating_params)
				setComplicatingSelects(activeUncs[index as number]?.uncCell?.selectsUncComplicating)
			}
		}
	}, [anchorEl, activeUncs])

	useEffect(() => {
		return () => {
			dispatch(setActiveUncs([]))
		}
	}, [])

	useEffect(() => {
		if (activeUncs) {
			const actualUnc = uncs.find((unc: IUnc) => unc.id === getId())
			actualUnc && dispatch(setActiveUncs([actualUnc]))
		}
	}, [uncs])
	useEffect(() => {
		if (activeUncs && activeUncs[index as number]) {
			setUncSort(activeUncs[index as number]?.unc_sort)
		}
	}, [activeUncs[index as number]?.unc_sort])

	return (
		<div>
			{tbodyArr
				.filter(el => el.tableNum == checkedTable)
				.map((item: any) => (
					<RowCells
						key={item.tableNum}
						checkedTable={checkedTable}
						tableNum={item.tableNum}
						currentUncSort={originalObj.unc_sort ?? null}
						title={item.title}
						uncs={item.unc(item.tableNum, uncs)}
						uncsFiltered={uncsFiltered}
						currentCellTags={item.tableNum === 8 ? cellTags[1] : cellTags[0]}
						voltageRef={voltageRef}
						isArchived={isArchived}
					/>
				))}

			{anchorEl?.dataset.popup === 'comment' && (
				<Comment
					anchorEl={anchorEl!}
					setAnchorEl={setAnchorEl!}
					objectName={objectName}
					stage={stage}
					kf={kf}
					description={description}
					comment={comment}
					setObjectName={setObjectName}
					setStage={setStage}
					setKf={setKf}
					setDescription={setDescription}
					setComment={setComment}
					handler={pushCommentPopup}
					isArchived={isArchived}
					uncSort={uncSort}
					setComplicatingParams={setComplicatingParams}
					complicatingParams={complicatingParams}
					id={getId()}
					complicatingSelects={complicatingSelects}
					coefficentUpdate={coefficentUpdate}
				/>
			)}
			{anchorEl?.dataset.popup === 'region' && (
				<Region
					anchorEl={anchorEl!}
					setAnchorEl={setAnchorEl!}
					regionList={regionList}
					handler={() => confirmRegionHandler(checkedRegionId, checkedZoneId)}
					checkedRegionId={checkedRegionId!}
					setCheckedRegion={setCheckedRegion}
					checkedZoneId={checkedZoneId}
					setCheckedZoneId={setCheckedZoneId}
				/>
			)}
			{anchorEl?.dataset.popup === 'volt-unc-table' && (
				<Voltage
					voltageRef={voltageRef}
					uncVoltage={voltageRef.current}
					targetVoltageValues={getTargetVoltageValues(uncs, anchorEl?.dataset.uncid)}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl!}
					handler={(voltage: number) => {
						dispatch(changeVoltage(accessoryRef?.current as string, voltage, false))
						setAnchorEl!(null!)
					}}
				/>
			)}
		</div>
	)
}
export { Tbody }
